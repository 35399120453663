import ReactDOM from 'react-dom/client';
import App from './App.tsx';
import './index.css';
import 'vite/modulepreload-polyfill';
import { retrieveLaunchParams, swipeBehavior, init } from '@telegram-apps/sdk';
import { setTelegramMiniAppUser } from 'stores/api/index';
import { initSentry } from './integrations';

initSentry();

if ((window as any).Telegram.WebApp.initData) {
  try {
    const launchParams = retrieveLaunchParams();
    setTelegramMiniAppUser(launchParams);
  } catch {
    // nothing
  }

  try {
    init();
    if (swipeBehavior.mount.isAvailable()) {
      swipeBehavior.mount();
      swipeBehavior.isMounted();
      swipeBehavior.disableVertical();
      swipeBehavior.isVerticalEnabled();
    }
  } catch (err) {
    console.error(err);
  }
}

// Для фиксов отображения с клавиатурой
if ('virtualKeyboard' in navigator && navigator.virtualKeyboard) {
  (navigator.virtualKeyboard as any).overlaysContent = true;
}

// Функция для нормализации пути
function normalizePath(path: string) {
  return path.replace(/\/+/g, '/'); // Заменяем несколько слешей на один
}

// Получаем текущий путь
const currentPath = window.location.pathname;

// Нормализуем путь
const normalizedPath = normalizePath(currentPath);

// Если текущий путь отличается от нормализованного, выполняем редирект
if (currentPath !== normalizedPath) {
  const newUrl = normalizedPath + window.location.search + window.location.hash;
  window.location.replace(newUrl); // Используем replace, чтобы избежать добавления записи в историю
}

ReactDOM.createRoot(document.getElementById('root')!).render(<App />);
